/* APP */
html,head,body {
    width: 100%;
    height:100%
}
.trust-section{
    width: 100%;
    float: left;
}
.main-container {
    padding: 0;
    margin: 0;
    height: 100%;
    width: 100%;
}

/* NAVBAR */
.MuiSvgIcon-root {
    fill: currentColor;
    width: 1em;
    height: 1em;
    display: inline-block;
    font-size: 1.5rem;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    flex-shrink: 0;
    user-select: none;
    color: #FF5456;
}

/* HOME */
.home-section{
    width:100%;
    color: #FFFFFF;    
    min-height: 85vh;
    height:85vh;
    /* border:1px solid blue; */
}
.home-content-section{
    width:70%;
    background-color: #FF5456;
    float:left;
    border-bottom-right-radius: 100% 60%;
    height: 100%;
    padding: 5% 0;
}
.home-content-section p {
    margin: auto;
    width: 90%;
    font-size: 20px;
    font-weight: 400;
    padding: 10px 0;
}
.home-content-section h1 {
    margin: auto;
    width: 90%;
}
.content-adjust{
    width:100%;
}
.home-slide-section{
    float: left;
    width: 30%;
}
.image-slider{
    border-top-right-radius: 5%;
    border-bottom-left-radius: 5%;  
}
.image-slider div{
    border-top-right-radius: 5%;
    border-bottom-left-radius: 5%;
}
.app-icon-section{
    width:10%;
    float:right;
}
.app-icon-section .MuiSvgIcon-root{
    color: #DCDCDC;
    font-size: 18vw;
}

.donate-now-section{
    width: 100%;
    float: left;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/* FOOTER */
/* .footer-section{
    width:100%;
    color: #FFFFFF;
    float:left;
    min-height: 85vh;
    height:85vh;
}
.footer-content-section{
    width:100%;
    background-color: #FF5456;
    float:left;
    border-top-left-radius: 100% 60%;
    height: 100%;
    padding: 5% 0;
}
.footer-content-section img{
    width: auto;
    height: 70px;
}
.footer-content-adjust p{
    float: right;
}
.footer-content-adjust h1{
    float: right;
}
.socialmedia-icons{
    margin-left: 3%;
}
.socialmedia-icons .MuiSvgIcon-root {
    fill: currentColor;
    width: 1em;
    height: 1em;
    display: inline-block;
    font-size: 1.5rem;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    flex-shrink: 0;
    user-select: none;
    color: #000000;
    margin: 0 1%;
} */
