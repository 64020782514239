.home-row{
    margin: 0;
    width: 100%;
    /* height: 100%; */
    height: 75vh;   
    display: flex;
    background-image: url("../../assets/images/Top_Banner.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom center;
}
.home-row .home-background {
    width: 100%;
    height: 100%;
}
.home-col{
    padding: 0;    
    display: flex;
}
.home-img-col {
    padding: 0;
    margin: auto;    
}
.home-bg{
    width: 70%;
    height: 70vh;
    background-color: #FF5456;
    border-bottom-right-radius: 100% 60%;
}
.text-content {
    color: #ffffff;
    margin: auto;    
}
.donate-home-btn {
    display: none;
}
.carousel .thumbs-wrapper{
    display: none;
}
.carousel.carousel-slider {
    position: relative;
    margin: 0;
    overflow: hidden;
    border-top-right-radius: 20px;
    border-bottom-left-radius: 20px;
}
.carousel-root {
    padding: 0 20%;
}
.carousel .slide img {
    width: 100%;
    vertical-align: top;
    border: 0;
    height: 100%;
    object-fit: cover;
}
.home-row .direction-icon{
    width: 10%;
    max-width: 45px;
    max-height: 50px;
    background-color: #FF5456;
    border: none;
    padding: 0;
    margin: 0 10px;
    box-sizing: border-box;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius:0;
}
@media only screen and (max-width : 768px) {
    .home-row{
        height: 100vh;
    }
    .text-quote{
        padding: 20px 40px;
    }
    .text-content {
        color: #ffffff;
        margin: auto;
        text-align: center;
        font-size: 20px;
    }
    .donate-home-btn {
        display: inline-flex;
        background-color: #FFFFFF;
        border: 1px solid #FFFFFF;
        padding: 5px 15px;
        color: #FF5456 ;
        font-size: 20px ;
        font-weight: 500;
        border-bottom-left-radius: 10px;
        border-top-right-radius: 10px
    }
    .donate-home-btn:hover , .donate-home-btn:focus  {
       color: #FF5456 ;
       background-color: #FFFFFF;
        border: 1px solid #FFFFFF;
        box-shadow: none;
    }
    .donate-home-btn:not(:disabled):not(.disabled):active {
       color: #FF5456 ;
        background-color: #FFFFFF;
        border: 1px solid #FFFFFF;
        box-shadow: none;
    }
    .carousel-root {
        padding: 0 10%;
    }
}
