
.trust-logo img{
    height: 60px;
    width: auto;
}
.trust-logo{
    width:25%;
}

.navbar-light .navbar-nav .nav-link{
    font-size: 16px;
    font-weight: 500;
    color: #000000;
    margin: auto;
}
.donateButton{
    background-color: #FF5456;
    border: 1px solid #FF5456;
    padding: 5px 15px;
    color: #FFFFFF ;
    font-size: 16px ;
    font-weight: 400;
    border-bottom-left-radius: 7px;
    border-top-right-radius: 7px;
}
.donateButton:hover , .donateButton:focus  {
    color: #ffffff;
    background-color: #FF5456;
    border-color: #FF5456;
    box-shadow: none;
}
.donateButton:not(:disabled):not(.disabled):active {
    color: #ffffff;
    background-color: #FF5456;
    border: 1px solid #FF5456;
    box-shadow: none;
}
.nav-link-active {
    color: #FF5456 !important;
    font-weight: 500;
}
.navbar-top {
    position: sticky;
    top: 0;
    z-index: 10;
    padding: 0;
    box-sizing: border-box;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
}
html { scroll-behavior: smooth; } 
@media(max-width:992px){
    .donateButton{
        display: none;
    }
    .navbar-light .navbar-nav .nav-link{
        margin: 0;
    }
}