.MuiFormControl-root {
    border: 0;
    margin: 0;
    display: inline-flex;
    padding: 0;
    position: relative;
    min-width: 0;
    flex-direction: column;
    vertical-align: top;
    width: 100%;
}
.MuiFormControlLabel-root {
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    margin-left: 0;
    margin-right: 0;
    vertical-align: middle;
    -webkit-tap-highlight-color: transparent;
}
.donate-header {
    width: 100%;
    padding: 2%;
}
.donate-header h1{
    text-align: center;
}
.donate-header p{
    text-align: center;
    font-size: 16px;
    margin: 0;
    line-height: 24px;
    width: 70%;
    margin-right: auto;
    margin-left: auto;
}
.donate-divider{
    border-bottom: 4px solid #FF5456;
    width: 30px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1%;
    margin-top: 10%;
}
.radio-option{
    padding: 5%;
}
.donation-option{
    padding: 5%;
    background-color: #FF5456;
    color: hsl(0, 0%, 100%);
    font-size: 16px;
    font-weight: 500;
    display: flex;
}
.donation-card{
    width: 75%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    /* border-top-right-radius: 5%;
    border-bottom-left-radius: 5%; */
    margin: auto;
}
.btn-primary:not(:disabled):not(.disabled):active:focus {
    box-shadow: none;
}
.MuiTypography-body1 {
    font-size: 14px !important;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: 0.00938em;
}
.form-control {
    display: block;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #FFFFFF;
    background-color: #FF5456;
    background-clip: padding-box;
    border: 1px solid #FFFFFF;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    margin-bottom: 3%;
}
.form-control::-webkit-input-placeholder { color: white; }  /* WebKit, Blink, Edge */
.form-control:-moz-placeholder { color: white; }  /* Mozilla Firefox 4 to 18 */
.form-control::-moz-placeholder { color: white; }  /* Mozilla Firefox 19+ */
.form-control:-ms-input-placeholder { color: white; }  /* Internet Explorer 10-11 */
.form-control::-ms-input-placeholder { color: white; }  /* Microsoft Edge */
.form-control:focus {
    color: #FFFFFF;
    background-color: #FF5456;
    border-color: #FFFFFF;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25);
}

.donate-button{
    text-align: center;
}
.donateNowButton{
    background-color: #FFFFFF;
    border: 1px solid #FFFFFF;
    padding: 5px 15px;
    color: #FF5456;
    font-size: 14px;
    font-weight: 500;
    /* border-bottom-left-radius: 7px;
    border-top-right-radius: 7px; */
    margin: auto;
}
.donateNowButton:hover , .donateNowButton:focus  {
    color: #FF5456 ;
    background-color: #FFFFFF;
     border: 1px solid #FFFFFF;
     box-shadow: none;
 }
 .donateNowButton:not(:disabled):not(.disabled):active {
    color: #FF5456 ;
     background-color: #FFFFFF;
     border: 1px solid #FFFFFF;
     box-shadow: none;
 }

@media(max-width:992px){
    
    .donate-fields{
        margin-top: 10%;
        width: 94%;
    }
    .donate-button{
        margin: 10% auto;
    }
    .radio-option {
        max-height: 200px;
        overflow-x: auto;
        padding-top: 0;
    }
    .radio-option h5{
        padding-top: 10px;
        text-align: center;
        position: sticky;
        top: 0;
        background-color: #ffffff;
        z-index: 1;       
        /* box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2); */
    }    
}