
.works {
    margin: 10% 0 0 0;
}
.works-labels {
    padding: 0;    
}
.divider {
    padding: 0;
    z-index: 1;
    margin: 10px 0;
}
.work-divider{
    border-bottom: 4px solid #FF5456;
    width: 30px;
    margin:auto;
}
.divider h1 {
   text-align: center;
    padding: 10px;
}
.works-bg-img {
    width: 100%;
    position: absolute;
    right: 0;
    z-index: -1;
}
.work-img{
    margin-top: 2%;
    margin-bottom: 2%;
}
.work-img img{
    width: 100%;
    max-height: 400px;
    min-height: 400px;
    object-fit: cover;
    object-position: center; 
    border-top-right-radius: 25px;
    border-bottom-left-radius: 25px;    
}
.work-content{
    width: 90%;
    max-height: 400px;
    float: left;
    overflow-y: auto;
    min-height: 100px;
}
.work-content p{
    float: left;
}
.work-values {
    display: grid;
    place-content: center;
}
.work-values h6{
    cursor: pointer;
    line-height: 3;
    padding-left: 10px;
}
.read-button{
    color: royalblue;
    text-decoration: underline;
    background: white;
    border: 0;
}
button:focus {
    outline: 0;
}
#more {display: none;}
.highlight-text{
    color: #FF5456;
    border-left: 3px solid;
}
.work-values-responsive {
    display: none;
}
@media  only screen and (max-width : 1224px) {
    .work-values{
        display: none;               
    }   
    .work-values-responsive{
        display: flex;
        width: 100%;
        padding: 20px 0 ;
    }
    .highlight-text-responsive {
        color: #FF5456;
    }
    .work-values-responsive h6 {
        margin: auto;
        cursor: pointer;
    }
    .work-values-responsive .direction-icon{
        width: 10%;
        max-width: 45px;
        max-height: 50px;
        background-color: #FFFFFF;
        border: none;
        padding: 0;
        margin: 0 10px;
        box-sizing: border-box;
        box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
        border-bottom-right-radius: 10px;
    }
    .work-values-responsive .disable-direction-icon {
        background-color: #c3c3c3;
        opacity: 0.7;
    }
}