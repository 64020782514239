.trust-logo img{
    height: 40px;
    width: auto;
}
footer .trust-logo{
    width:25%;
    margin: auto;
}
.social-media .MuiSvgIcon-root {
    fill: currentColor;
    width: 1em;
    height: 1em;
    display: inline-block;
    font-size: 2rem !important;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    flex-shrink: 0;
    user-select: none;
    color: #000000 !important;
    font-weight: 500;
    margin-right: 4%;
}
.footer-top .row {
    margin: 0;
}
.footer-top ul{
    margin: 0;
    padding: 0;
    list-style:none;
    margin: auto 0 auto auto;
}
.footer-top ul li{
   cursor: pointer;
}
.footer-top ul li a {
    color: #ffffff;
    text-decoration: none;
}
footer {
    background-image: url("../../assets/images/Footer (2).jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top right;    
    color: #ffffff;
}
.footer-top {    
    height: 75vh;
    display: flex;
    margin: 0;
    line-height: 2;
}
.footer-logo-area {
    margin: auto auto auto 0;
    text-align: end;
}
.footer-address-area {
    margin: auto ;
    display: flex;
    padding: 0;
}
.footer-links-area {
    margin: auto ;
    display: flex;
    padding: 0;
}
.footer-links-area ul{
    margin: auto;
}
.footer-news-area {
    margin: auto ;
}
footer .MuiSvgIcon-root{
    color: #ffffff;
}
.footer-bottom .row {
    margin:0;    
    font-size: 13px;
    height: 10vh;
}
.copyrights {
text-align: center;
}
.techotron {
    text-align: end;    
}
.techotron a{
    color: #ffffff;
}
.social-media {
    margin-top: 20px;
}
@media  only screen and (max-width : 1224px) {
   
    footer{
        background-image: none;
        background-color: #FF5456;       
    }   
    
    .footer-top {
        display: flex;
        flex-wrap: wrap;
        height: auto;        
    }
    .footer-logo-area {
        background-color: #ffffff;
        margin: 0;      
    }
    footer .trust-logo{
        width:25%;
        margin: 0;
    }    
    .social-media {
        margin: 10px 0;
        padding-top: 10px;
        text-align: left;
    }
    .footer-news-area,.footer-news-area form  {
        margin: 10px 0;
        padding-left: 10px;
    }
    .footer-address-area,.footer-links-area {       
        margin: 10px 0;
        padding-left: 15px;
    }
     .footer-address-area ul,.footer-links-area ul{
        margin: 10px;
    }
    .copyrights {
    padding:3px 0 0 0;
    text-align: center;
    border-top: 1px solid #c3c3c3;
    }
    .techotron {
    padding: 0;
    text-align: center;
    }
   
    
}
/* @media only screen and (max-width : 768px) { 
    .footer-top {
        display: flex;
        flex-wrap: wrap;
        height: 120vh;        
    }
   
} */
