.gallery-header{
    width: 100%;
}
.gallery-header h1{
    text-align: center;
}
.gallery-divider{
    border-bottom: 4px solid #FF5456;
    width: 30px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1%;
    margin-top: 10%;
}
.gallery-img{
        margin: 0;
        width: 100%;
        height: 90vh;   
        display: flex;
        background-image: url("../../assets/images/ChildrenEducation.png");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: bottom center;
        margin-top: 2%;
}
.img-fluid {
    width: 100%;
    min-height: 250px;
    max-height: 250px;
    object-fit: cover;
    object-position: center;
}
/* Gallery  */
.direction-icon{
    width: 10%;
    max-width: 45px;
    height: 45px;
    max-height: 45px;
    background-color: #FF5456;
    border: none;
    padding: 0;
    margin: 0 10px;
    box-sizing: border-box;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    border-bottom-right-radius: 10px;
}
.gallery-buttons {
    padding:20px;
    display: flex;
    flex-direction: row;
}
.gallery-labels{
    width: 100%;
    text-align: center;
    color: #FF5456;
    background-color: #FFFFFF;
    border: none;
    font-size: 1.3rem;
    font-weight: 600;
    margin-left: 10%;
}
.disable-direction-icon {
    background-color: lightgray;
}
.gallery {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
    -webkit-column-width: 33%;
    -moz-column-width: 33%;
    column-width: 33%; }
    .gallery .pics {
    -webkit-transition: all 350ms ease;
    transition: all 350ms ease; }
    .gallery .animation {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1); }
    
    @media (max-width: 450px) {
    .gallery {
    -webkit-column-count: 1;
    -moz-column-count: 1;
    column-count: 1;
    -webkit-column-width: 100%;
    -moz-column-width: 100%;
    column-width: 100%;
    }
    }
    .filter-btn{
        border:1px solid #FF5456;
        margin: 0 5px;
    }
    .filter-btn:hover{
        color: #FFFFFF;
        background-color: #FF5456;
    }

    @media only screen and (max-width : 768px) {
        .gallery-img{
            height: 35vh;
        }
    }
    @media only screen and (max-width : 414px) {
        .gallery-img{
            height: 20vh;
        }
    }
    @media only screen and (max-width : 1024px) {
        .gallery-img{
            height: 30vh;
        }
    }
    @media (max-width: 400px) {
    .btn.filter {
    padding-left: 1.1rem;
    padding-right: 1.1rem;
    }
    .gallery-img{
        height: 20vh;
    }
    }